<template>
  <div class="container-fluid">
    <div
      class="col-12 row justify-content-between container-instructors__search pr-0 pl-0 ml-0 mb-3"
    >
      <div class="col-xs-courses col-sm-6 col-md-6 col-lg-6 col-lg-6 col-xl-6">
        <form class="filter-flex" style="margin-left: -15px">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            aria-label="Search"
            class="form-control inputFilter md-filter-input me-2"
            placeholder="Buscar por nombre"
            type="text"
          />
          <div class="table-instructor md-layout md-gutter">
            <div class="md-layout-item p-0">
              <md-field class="sort-box">
                <md-select
                  v-model="sortType"
                  class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                >
                  <md-option hidden selected value="">Filtrar por</md-option>
                  <md-option value="newest">Nuevo</md-option>
                  <md-option value="oldest">Antiguo</md-option>
                  <md-option value="asc">A-Z</md-option>
                  <md-option value="desc">Z-A</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </form>
      </div>
      <div
        class="col-xs-instructor col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pr-0"
      >
        <div class="float-right button-instructor">
          <base-button class="btn-course"> AGREGAR SECUENCIA </base-button>
        </div>
      </div>
    </div>

    <div class="container-instructors__body">
      <div class="container-instructors">
        <md-table>
          <md-table-row class="container-instructors__head">
            <md-table-head>Nombre</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      sortType: '',
    };
  },
  watch: {
    sortType: function () {},
  },
  methods: {},
};
</script>

<style scoped>
.container-instructors {
  padding: 0px !important;
}
</style>
